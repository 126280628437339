<template>
  <div>
    <checkout upgrade="false" />
  </div>
</template>

<script>
import Checkout from './CheckoutPagamento.vue'

export default {
  components: {
    Checkout,
  },

  data() {
    return {

    }
  },

}
</script>
